import { Component } from '@angular/core';

@Component({
  selector: 'app-view-videos',
  templateUrl: './view-videos.component.html',
  styleUrls: ['./view-videos.component.css']
})
export class ViewVideosComponent {

}
