<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- Container wrapper -->
  <div class="container">

    <!-- Navbar brand -->
    <a class="navbar-brand me-2">
      <img src="../../assets/logo.png" height="40" alt="Farmrise Logo" loading="lazy" style="margin-top: -1px;" />
    </a>

    <!-- Toggle button -->
    <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarButtonsExample"
      aria-controls="navbarButtonsExample" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div *ngIf="loginDisplay" class="collapse navbar-collapse" id="navbarButtonsExample">
      <!-- Left links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" >
          <a href="#">Dashboard</a>
        </li>
      </ul>
      <!-- Left links -->

      <div class="d-flex align-items-center">

        <!-- filed officer menu -->
        <div class="btn-group">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-mdb-toggle="dropdown" data-mdb-auto-close="true" aria-expanded="false">
            Field Officer
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="field-officer">Field Officer List</a></li>
            <li><a class="dropdown-item" routerLink="field-officer-migrate">Field Officer Migrate</a></li>
            <li><a class="dropdown-item" routerLink="create-field-officer">Create Field Officer</a></li>
            <li><a class="dropdown-item" routerLink="migrate-field-officer-partial">Field Officer Migrate Partial</a></li>
          </ul>
        </div>

        <!-- Loyalty menu -->
        <div class="btn-group">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-mdb-toggle="dropdown" data-mdb-auto-close="true" aria-expanded="false">
            Loyalty User
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="blocked-users-list">Blocked User List</a></li>
            <li><a class="dropdown-item" routerLink="block-users">Block Users</a></li>
            <li><a class="dropdown-item" routerLink="unblock-users">UnBlock Users</a></li>
          </ul>
        </div>

        <!-- Video Upload Menu -->
        <div class="btn-group">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-mdb-toggle="dropdown" data-mdb-auto-close="true" aria-expanded="false">
            Video Feeds
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="upload-videos">Upload Videos</a></li>
            <li><a class="dropdown-item" routerLink="view-videos">View/Edit Videos</a></li>
          </ul>
        </div>

        <button type="button" class="btn btn-primary me-3">
          <a class="nav-link" (click)="logout()">logout</a>
        </button>
      </div>
    </div>
    <!-- Collapsible wrapper -->
  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->


